<template>
  <div>
  <div @click="$modal.show(TYPE+'-date')" class="ui segment title">
    <div class="ui header">
      Demande {{TITLE}}
    </div>
  </div>

  <h3>Liste des {{TITLE.toLowerCase()}}</h3>
  <div class="m-1">
    <tableType1 v-bind="table" @onSetPageClick="setPage" />
  </div>

    <!--        Modal Confirmation-->
    <modal :name="TYPE+'-date'" width="500px" style="border-radius: 20px;" :adaptive="true" height="auto">
      <div class="ui grid center aligned" style="padding: 10px;">
        <div class="row">
          <div class="seven wide m-1">
            <h5>Entrer la date souhaitée</h5>
          </div>
          <div class="seven wide">
            <div class="ui input focus">
              <input type="date" v-model="date" placeholder="Date">
            </div>
          </div>
        </div>
        <button @click="createDemande" class="default-button m-1">
          Soumettre
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
    import tableType1 from "@/components/generic/tableType1";

    export default {
  name: "soutenance_template",
  components:{tableType1},
  props:{
    soutenance:{
      type:Boolean,
      required: true
    }
  },
  data(){
    return{
      table: {
        action: false,
        data: [],
        headers: [
          {label: "Demande", value: "_id",filter:(id)=>this.table.data.findIndex(e=>e._id===id)+1},
          {label: "Date Demande", value: "tmsp",filter:(data)=>this.$SHARED.utils.getDateOnly(data)},
          {label: "Date Soutenance", value: "date",filter:(data)=>this.$SHARED.utils.getDateOnly(data)},
          {label: "statut", value: "status", filter:(data)=>data==='waiting'?'en Attente':data==='validated'?'Validé':'Refusé'}
        ],
        pagination: {
          page: 1,
          limit: 10,
          count: 0
        },
        isBusy: false
      },
      date:null
    }
  },
  computed:{
    CURRENT_USER(){return this.$store.state.current_user},
    TITLE(){return this.soutenance ? 'Soutenances' : 'Pré-Soutenances'},
    TYPE(){return this.soutenance ? 'soutenance' : 'presoutenance'}
  },
  methods:{
    setPage(page) {
      console.log("page: " + page)
      this.table.pagination.page = page
      this.getSoutenanceList()
    },
    getSoutenanceList() {
      console.log('getSoutenance');
      const data = {
        query: {
          user_id: this.CURRENT_USER._id,
          type:this.TYPE
        },
        options: {
          limit: this.table.pagination.limit,
          page: this.table.pagination.page,
          withCount: true
        }
      };
      this.table.isBusy = true;
      this.$store.dispatchAsync(this.$SHARED.services.demandeSoutenance.list, data).then(ar => {
        this.table.data = ar.docs;
        this.table.pagination.count = ar.count;
      }).finally(() => {
        this.table.isBusy = false;
      })
    },
    createDemande(){
      console.log('createDemande');
      if(!this.date){
        this.$toast.error('Veuillez selectionnez une date')
        return
      }
      const data = {
          user_id: this.CURRENT_USER._id,
          type:this.TYPE,
          status:'waiting'
      };

      this.$store.dispatchAsync(this.$SHARED.services.demandeSoutenance.list,{
        query:{...data},
        options:{}
      }).then(list => {
        if(!_.isEmpty(list)){
          this.$modal.hide(this.TYPE+'-date')
          this.$toast.error(this.$SHARED.messages.demandeSoutenance.create_failed)
        }else {
          data.date=this.$SHARED.utils.getMilliseconds(this.date)

          this.$store.dispatchAsync(this.$SHARED.services.demandeSoutenance.create, data).then(ar => {
            this.getSoutenanceList()
            this.$modal.hide(this.TYPE+'-date')
            this.$toast.success(this.$SHARED.messages.demandeSoutenance.create_succeeded)
          })
        }
      })
    }
  },
  mounted() {
    this.getSoutenanceList()
  }
}
</script>

<style scoped>
.segment.title{
  width: 50% !important;
  margin: auto;
  text-align: center;
  border-color: var(--main-color-blue-dark);
}
.segment.title:hover{
  cursor: pointer;
  background: lightgrey;
  transition: ease .2s;
}
.ui.header{
  color: var(--main-color-blue-dark);
}
h3{
  margin: 20px;
}
</style>